import TinyMCEEditorV2 from '../component/TinyMCEEditorV2.vue';
import Multiselect from "vue-multiselect";   

export default {
    name: "UpdateBoletin",
    components:{
      TinyMCEEditorV2,
      Multiselect
    },
    data() {
      return {
        mostrarNotificacion: 0,
        mostrarNotificacionAlerta: 0,
        mostrarNotificacionError: 0,
        mensaje_success: "Boletin Creado Correctamente",
        mensaje_warning: "Debe Completar Los Datos Vacio",
        form_create_boletin:{
          nombre:'',
          asunto:'',
          mensaje:'',
          grupo:'',
          id:'',
          email_copia:'',
        },
        items_grupos:[],
        fileInputKey: 0,
        parametros_configuracion: {
          limite_archivos: 1,
          limite_tamano_archivo: 1
        },
        archivos_envio: null,
        archivo_cargado: [],
        archivo_cargado_valida: [],
        modal_eliminar_boletin: false,
        varItemsGrupos: [],
        items_grupos_all:[],
        varItemsAsuntos: [],
        varItemsAsuntosValue:0,
      };
    },
    methods: {
      getGrupos(){
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
          .get(this.$httpUrlMail+"/mail/envio/grupos",{withCredentials:false})
          .then((response) => {
            let data = response.data;
            for (const iterator of data) {
              this.items_grupos_all = data;
              this.items_grupos.push({
                code: iterator.id_grupo,
                name:
                  iterator.nombre_grupo +
                  ` (${this.$formatterNumeroTipoCreditos(iterator.cantidad)})`,
              });
            }
            let id = this.$route.params.id;
            this.showBoletin(id);
          })
          .catch(() => {
            this.mostrarNotificacionError++;
          })
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      },
      getConfigUser(){
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
          .get(this.$httpUrlMail+"/mail/config/user",{withCredentials:false})
          .then((response) => {
             this.parametros_configuracion = response.data;
          })
          .catch(() => {
            this.mostrarNotificacionError++;
          })
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      },
      updateBoletin(evt){
        const contenido = this.$refs.tinyMCEEditorV2.getContent();
        this.form_create_boletin.mensaje = contenido;
        let id = this.$route.params.id;
        if (this.form_create_boletin.asunto == "" || this.form_create_boletin.mensaje == "" || this.form_create_boletin.nombre == "") {
          this.mostrarNotificacionAlerta++;
          this.mensaje_warning = "Debe Completar Los Datos Vacio";
          return;
        }
        if (this.form_create_boletin.grupo.length == 0) {
          this.mostrarNotificacionAlerta++;
          this.mensaje_warning = "Debe Seleccionar un Grupo";
          return;
        }
        if(this.validateEmail(this.form_create_boletin.email_copia) == false){
          this.mostrarNotificacionAlerta++;
          this.mensaje_warning = "El Email de Copia no es Valido";
          return;
        }
        let InstFormData = new FormData();
        InstFormData.append("asunto",this.form_create_boletin.asunto);
        InstFormData.append("mensaje",this.form_create_boletin.mensaje);
        InstFormData.append("nombre",this.form_create_boletin.nombre);
        InstFormData.append("email_copia",this.form_create_boletin.email_copia);
        InstFormData.append('_method', 'patch')
        const soloIDs = this.form_create_boletin.grupo.map(objeto => objeto.code);
        InstFormData.append("grupo",soloIDs.join(','));
        InstFormData.append("contiene_archivos",this.validateIsEmpty());
        if (this.archivos_envio != null) {
          for (const file of this.archivos_envio) {
            InstFormData.append("archivos[]", file);
          }
        }
        evt = (evt) ? evt : window.event;
        evt.target.disabled = true;
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
          .post(this.$httpUrlMail+"/mail/boletines/"+id,InstFormData,{withCredentials:false},
          {
            headers: { "content-type": "multipart/form-data" },
          })
          .then((response) => {
            if (response.data.status == "OK") {
              this.resetFormBoletin();
              this.mostrarNotificacion++;
              this.mensaje_success = response.data.message;
              setTimeout(() => {
                this.$router.push({ path: "/mailing/boletines" });
              }, 2000);
            } else {
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta++;
            }
            evt.target.disabled = false;
          })
          .catch(() => {
            this.mostrarNotificacionError++;
          })
          .finally(() => {this.$store.dispatch("auth/loading_http", "hidden");evt.target.disabled = false;});
          evt.target.disabled = false;
          
      },
      resetFormBoletin(){
        this.form_create_boletin = {
          nombre:'',
          asunto:'',
          mensaje:'',
          grupo:'',
          id:'',
          email_copia:'',
        };
        this.archivos_envio = null;
        this.fileInputKey++;
      },
      cargarArchivoEnvio(files, event) {
        let archivos = files;
        let valida_contador = 0;
        let calcula_tamano = 0;
        if (archivos.length == 0) {
            this.fileInputKey++;
            this.archivos_envio = null;
            this.mostrarNotificacionAlerta++;
            this.mensaje_warning = "Debe Seleccionar un Archivo";
            return;
        }
        if (archivos.length > this.parametros_configuracion.limite_archivos) {
            this.fileInputKey++;
            this.archivos_envio = null;
            this.mostrarNotificacionAlerta++;
            this.mensaje_warning = `Solo se Permite ${this.parametros_configuracion.limite_archivos} Archivos`;	
            return;
        }
        for (const iterator of archivos) {
          let extension = this.getFileExtension2(iterator.name);
          if (extension.toLowerCase() !== "pdf") {
              valida_contador++;
          }
          if (iterator.name == undefined) {
              valida_contador++;
          }
          calcula_tamano += iterator.size;
        }

        if (valida_contador > 0) {
            this.fileInputKey++;
            this.archivos_envio = null;
            this.mostrarNotificacionAlerta++;
            this.mensaje_warning = "Solo se Permite Archivos PDF";
            return;
        }
        const maxSizeInBytes = this.parametros_configuracion.limite_megas_archivos * 1024 * 1024;
        if (calcula_tamano > maxSizeInBytes) {
            this.fileInputKey++;
            this.archivos_envio = null;
            this.mostrarNotificacionAlerta++;
            this.mensaje_warning = `El archivo debe pesar menos de ${this.parametros_configuracion.limite_megas_archivos}MB`;
            return;
        }

        this.archivos_envio = files;
      },
      getFileExtension2(filename) {
        return filename.split(".").pop();
      },
      showBoletin(id){
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http.get(this.$httpUrlMail+"/mail/boletines/"+id,{withCredentials:false})
          .then((response) => {
             this.form_create_boletin.nombre = response.data.nombre;
             this.form_create_boletin.asunto = response.data.asunto;
             this.form_create_boletin.email_copia = response.data.email_copia;
             this.form_create_boletin.id = response.data.id;
             setTimeout(() => {
              this.form_create_boletin.mensaje = response.data.mensaje;
             this.$refs.tinyMCEEditorV2.setContent(response.data.mensaje);
             }, 400);
             this.form_create_boletin.grupo = [];
             let gruposIds = (response.data.id_grupo).split(',');
             let gruposSelected = [];
             for (const iterator of gruposIds) {
                if (iterator > 0) {
                  let tempA = this.items_grupos.find(x => x.code == iterator);
                  if (tempA != undefined) {
                    gruposSelected.push(tempA); 
                  }
                }
             }
             this.form_create_boletin.grupo = gruposSelected;
             this.setVarsItemsGrupos();
             try {
              this.archivo_cargado = JSON.parse(response.data.archivos);
              this.archivo_cargado_valida = this.archivo_cargado;
             } catch (error) {
              console.log(error);
             }
          })
          .catch((e) => {
            this.mostrarNotificacionError++;
            console.log(e);
          })
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
      },
      eliminarArchivo(){
        this.archivos_envio = null
        this.archivo_cargado = []
        this.modal_eliminar_boletin = false
      },
      validateIsEmpty() {
        if(this.archivo_cargado.length == 0 && this.archivos_envio == null && this.archivo_cargado_valida.length > 0){
          return 3;
        }else if(this.archivo_cargado.length == 0 && this.archivos_envio == null){
          return 1;
        }else if(this.archivo_cargado.length == 0 && this.archivos_envio != null){
          return 2;
        }else if(this.archivo_cargado.length > 0 && this.archivos_envio == null){
          return 1;
        }else{
          return 1;
        }
      },
      b64DecodeUnicode(str) {
        // Going backwards: from bytestream, to percent-encoding, to original string.
        return decodeURIComponent(atob(str).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
      },
      limitarCaracteresV2(event) {
        let value = event.target.value;
        event.target.value = this.NumTextV2(value);
        this.form_create_boletin.asunto = this.NumTextV2(value);
      },
      NumTextV2(string) {
        //solo letras y numeros
        var out = "";
        //Se añaden las letras validas
        //áéíóúÁÉÍÓÚ
        var filtro =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-_ ñÑ"; //Caracteres validos
  
        for (var i = 0; i < string.length; i++)
          if (filtro.indexOf(string.charAt(i)) != -1) out += string.charAt(i);
        return out;
      },
      setVarsItemsGrupos(){
        let array_selected = this.form_create_boletin.grupo;
        if (array_selected.length > 0) {
          this.varItemsGrupos = [];
          this.varItemsAsuntos = [];
          this.varItemsAsuntosValue ++;
          let tempA = [];
          for (const iterator of array_selected) {
             let tempB = this.items_grupos_all.find(x => x.id_grupo == iterator.code);
             if (tempB != undefined) {
               tempA.push(tempB.campos_personalizados);
             }
          }
          for (const iterator of tempA) {
             try {
                let jsonTemp = JSON.parse(iterator);
                for (const iterator2 of jsonTemp) {
                    let tempC = this.varItemsGrupos.find(x => x.name === iterator2.field);
                    if (tempC == undefined) {
                      this.varItemsGrupos.push({name:iterator2.field});
                      this.varItemsAsuntos.push({value:iterator2.field,label:iterator2.field});
                    }
                }
             } catch (error) {
              console.log(error);
             }
          }
          this.$refs.tinyMCEEditorV2.setVarsItemsGrupos(this.varItemsGrupos);  
        }
      },
      setVarItemsAsuntos(evt){
        let value = evt.target.value;
        if (value != "") {
          this.form_create_boletin.asunto += ` {{${value}}}`;
          this.varItemsAsuntosValue ++;
        }
        return false;
      },
      validateEmail(email) {
        try {
          email = email.trim();
        } catch (error) {}
        if (email == "" || email == undefined || email == null) {
          return true;
        }
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (email.match(mailformat)) {
          return true;
        }
        return false;
      },
    },
    mounted() {
      setTimeout(() => {
          this.getGrupos();
          this.getConfigUser();
      }, 100);
    },
  };